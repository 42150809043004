import React from 'react'
import starIcon from '../../assets/images/star-icon.png'
import client1 from '../../assets/images/testimonials/client1.jpg'
import client2 from '../../assets/images/testimonials/client2.jpg'
import client3 from '../../assets/images/testimonials/client3.jpg'

const TestimonialsStyleOne = () => {
    return (
        <div className="testimonials-area pt-100 pb-70 bg-f1f8fb">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="about" /> 
                        Testimonials
                    </span>
                    <h2>What Our Clients are Saying?</h2>
                    <p> AyasCloud team feels always great about our clients testimonials, which are really boosting our motivation.</p>
                </div>

                <div className="row">
                    <div className="col-lg-6 col-md-6">
                        <div className="single-testimonials-item">
                            <p>AyasCloud brought up great flexibility for our testing team. My entire regression test suite is now automated and runs monthly. This has saved me about three days’ worth of testing time. Now, all I have to do is look at the results and do real test analysis, instead of wasting time pressing buttons.</p>
                            <div className="client-info">
                                <div className="d-flex justify-content-center align-items-center">
                                    <img src={client1} alt="about" />
                                    <div className="title">
                                        <h3>Alex Maxwell</h3>
                                        <span>QA Manager</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                        <div className="single-testimonials-item">
                            <p>AyasCloud team is innovative and flexible in resolving challenges with their innovative thought process, which unblockes us in our CICD success journey.</p>
                            <div className="client-info">
                                <div className="d-flex justify-content-center align-items-center">
                                    <img src={client2} alt="about" />
                                    <div className="title">
                                        <h3>David Warner</h3>
                                        <span>CEO</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                        <div className="single-testimonials-item">
                            <p>Awesome team we got from AyasCloud, who ramp up our QA completion time from days to an hour with highest quality strategies, I would recommend this AyasCloud in circle with the impression I got.</p>
                            <div className="client-info">
                                <div className="d-flex justify-content-center align-items-center">
                                    <img src={client3} alt="about" />
                                    <div className="title">
                                        <h3>Mithra Jaladi</h3>
                                        <span>Principle Architect</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                        <div className="single-testimonials-item">
                            <p>Our DevOps journey was bit struggling before AyasCloud team joined with us, after AC team arrival we truely saw the difference our DevOps process extremely faster .</p>
                            <div className="client-info">
                                <div className="d-flex justify-content-center align-items-center">
                                    <img src={client1} alt="about" />
                                    <div className="title">
                                        <h3>Alex Maxwell</h3>
                                        <span>CEO</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TestimonialsStyleOne